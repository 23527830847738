import { useState, useCallback } from 'react'

import { Divider, Loader } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { Metadata, useMutation, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import QuestionRow from 'src/components/Inputs/QuestionRow/QuestionRow'
import { PDFPreviewModal } from 'src/components/Overlays/PDFPreviewModal/PDFPreviewModal'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_VOE_BY_ID,
  SUBMIT_VOE_RESPONSE_CONTENT,
} from 'src/graphql/voe.graphql'
import IconAlertCircle from 'src/icons/IconAlertCircle'
import { downloadFile } from 'src/utils/downloadFile'

import AccidentsCard from '../ApplicantDetailsPage/tabs/ApplicationTab/components/AccidentsCard'

import AuditTrailCard from './components/AuditTrailCard'
import CompanyInfoCard from './components/CompanyInfoCard'
import DriverInfoCard from './components/DriverInfoCard'
import RequestContentColumn from './components/RequestContentColumn'
import RequestInfoCard from './components/RequestInfoCard'
import ResponseContentColumn from './components/ResponseContentColumn'
import VoEConsentCard from './components/VoEConsentCard'
import VoEDetailsHeader from './components/VoEDetailsHeader'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const VoERequestDetailsPage = ({ id }) => {
  const [openConsent, setOpenConsent] = useState(false)

  const schema = z.object({
    positionHeld: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(200, { message: '200 character max' }),
    startDate: z.date(),
    endDate: z.date(),
    reasonForLeaving: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(1000, { message: '1000 character max' }),
    eligibleForRehire: z.boolean(),
    isDriverTerminated: z.boolean(),
    isDriverSubjectToFMCSRs: z.boolean(),
    isJobDesignatedAsSafetySensitiveFunctionUnderDOT: z.boolean(),
    accidents: z.array(
      z.object({
        accidentDate: z.date().optional(),
        city: z.string().min(1).max(100),
        state: z.string().min(2).max(50),
        description: z.string().min(1).max(1000),
        isPreventable: z.boolean(),
        isCommercialMotorVehicleInvolved: z.boolean(),
      })
    ),
  })

  const form = useForm({
    validate: zodResolver(schema),
  })

  const [submitVoEResponse, { loading: loadingSubmit }] = useMutation(
    SUBMIT_VOE_RESPONSE_CONTENT,
    {
      refetchQueries: [
        {
          query: GET_VOE_BY_ID,
          variables: {
            id: id,
          },
        },
      ],
    }
  )

  const { data: voe, loading } = useQuery(GET_VOE_BY_ID, {
    variables: {
      id: id,
    },
    onCompleted: () => {
      handleInitialValues()
    },
    onError,
  })

  const handleSubmit = (values) => {
    submitVoEResponse({
      variables: {
        id,
        input: {
          ...values,
        },
      },
    })
  }

  const handleInitialValues = () => {
    form.setValues(
      voe?.verificationOfEmployment.status === 'COMPLETED'
        ? {
            positionHeld:
              voe.verificationOfEmployment.responseContent.positionHeld,
            startDate: new Date(
              voe.verificationOfEmployment.responseContent.startDate
            ),
            endDate: new Date(
              voe.verificationOfEmployment.responseContent.endDate
            ),
            reasonForLeaving:
              voe.verificationOfEmployment.responseContent.reasonForLeaving,
            eligibleForRehire:
              voe.verificationOfEmployment.responseContent.eligibleForRehire ||
              false, //todo add
            isDriverTerminated:
              voe.verificationOfEmployment.responseContent.isDriverTerminated,
            isDriverSubjectToFMCSRs:
              voe.verificationOfEmployment.responseContent
                .isDriverSubjectToFMCSRs,
            isJobDesignatedAsSafetySensitiveFunctionUnderDOT:
              voe.verificationOfEmployment.responseContent
                .isJobDesignatedAsSafetySensitiveFunctionUnderDOT,
            accidents: voe.verificationOfEmployment.accidents,
          }
        : {
            accidents: voe.verificationOfEmployment.accidents,
            positionHeld:
              voe.verificationOfEmployment.requestContent.positionHeld,
            startDate: new Date(
              voe.verificationOfEmployment.requestContent.startDate
            ),
            endDate: new Date(
              voe.verificationOfEmployment.requestContent.endDate
            ),
            reasonForLeaving:
              voe.verificationOfEmployment.requestContent.reasonForLeaving,
            eligibleForRehire:
              voe.verificationOfEmployment.requestContent.eligibleForRehire ||
              false,
            isDriverTerminated:
              voe.verificationOfEmployment.requestContent.isDriverTerminated,

            isDriverSubjectToFMCSRs:
              voe.verificationOfEmployment.requestContent
                .isDriverSubjectToFMCSRs,
            isJobDesignatedAsSafetySensitiveFunctionUnderDOT:
              voe.verificationOfEmployment.requestContent
                .isJobDesignatedAsSafetySensitiveFunctionUnderDOT,
          }
    )
  }

  const isCompleted = voe?.verificationOfEmployment.status === 'COMPLETED'

  const handleAccidentsUpdate = async ({ accidents }) => {
    form.setFieldValue(`accidents`, accidents)
    return { errors: null }
  }

  const onPreviewDriverConsent = useCallback(() => {
    setOpenConsent(true)
  }, [])

  const onConsentDownload = useCallback(() => {
    downloadFile({
      url: voe.verificationOfEmployment.driverConsentFileUrl,
      fileName: 'consent.pdf',
    })
  }, [voe?.verificationOfEmployment?.driverConsentFileUrl])

  if (loading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <Loader />
      </div>
    )
  }
  return (
    <form
      className="flex h-full flex-col gap-6  overflow-auto px-6 pb-10"
      onSubmit={form.onSubmit((values) => handleSubmit(values))}
    >
      <Metadata
        title="IncomingVoEDetails"
        description="IncomingVoEDetails page"
      />
      <VoEDetailsHeader
        showSubmitButton={!isCompleted}
        loadingSubmit={loadingSubmit}
        driverName={voe.verificationOfEmployment.driverName}
      />
      <RequestInfoCard voe={voe.verificationOfEmployment} />
      <CompanyInfoCard voe={voe.verificationOfEmployment} />
      <DriverInfoCard voe={voe.verificationOfEmployment} />

      {/* REQUEST CONTENT */}
      <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
        <div className="flex flex-col gap-6 p-6">
          <div className="text-md font-semibold text-doubleNickel-gray-900">
            Request Content
          </div>
          <div className="flex flex-row gap-8 ">
            <RequestContentColumn
              form={form}
              request={voe.verificationOfEmployment.requestContent}
            />
            <Divider orientation="vertical" />
            <ResponseContentColumn
              editing={!isCompleted}
              form={form}
              request={voe.verificationOfEmployment.requestContent}
            />
          </div>
          <div className="flex flex-row items-center gap-4 rounded-lg bg-doubleNickel-brand-25 px-4 py-3">
            <IconAlertCircle className="fill-none stroke-doubleNickel-brand-500" />
            <div className="flex-1">
              <QuestionRow
                required
                text="Is this applicant eligible for rehire?"
                value={
                  isCompleted
                    ? voe.verificationOfEmployment.responseContent
                        .eligibleForRehire
                    : form.values.eligibleForRehire
                }
                handleChange={(value) =>
                  form.setFieldValue(`eligibleForRehire`, value === 'true')
                }
                editing={!isCompleted}
              />
            </div>
          </div>
        </div>
      </div>
      {form.values.accidents && (
        <AccidentsCard
          values={form.values.accidents}
          handleUpdateApplication={handleAccidentsUpdate}
          readOnly={isCompleted}
        />
      )}
      <VoEConsentCard onPreview={onPreviewDriverConsent} />

      {openConsent && (
        <PDFPreviewModal
          fileName="Driver consent"
          url={voe.verificationOfEmployment.driverConsentFileUrl}
          isOpen={openConsent}
          onClose={() => setOpenConsent(null)}
          onBack={() => setOpenConsent(null)}
          onDownload={onConsentDownload}
        />
      )}

      <AuditTrailCard
        auditTrailLogs={voe.verificationOfEmployment.auditTrailLogs}
      />

      {!isCompleted && (
        <div className="flex w-full flex-row justify-end">
          {!loadingSubmit && <Button text="Submit" type="submit" />}
          {loadingSubmit && <Loader size="sm" />}
        </div>
      )}
    </form>
  )
}

export default VoERequestDetailsPage
